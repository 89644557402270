var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Templates overzicht")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"has--clickable-row",attrs:{"stripe":"","data":_vm.templates}},[_c('el-table-column',{attrs:{"sortable":"","prop":"title","label":"Titel"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"version","label":"versie","width":"100","align":"right"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"versionDateFormatted","label":"Laatst gewijzigd","width":"170"}}),_c('el-table-column',{attrs:{"width":"175","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('context-menu',[_c('li',{staticStyle:{"width":"300px"}},[_c('router-link',{staticClass:"icon-button icon-button--text",attrs:{"to":{
                name: 'TemplateEdit',
                params: {
                  manualId: scope.row.id
                }
              }}},[_c('i',{staticClass:"fal fa-pen"}),_vm._v(" Gegevens template wijzigen ")])],1),_c('li',{staticStyle:{"width":"300px"}},[_c('router-link',{staticClass:"icon-button icon-button--text",attrs:{"to":{
                name: 'TemplateEditor',
                params: {
                  manualId: scope.row.id
                }
              }}},[_c('i',{staticClass:"fal fa-book"}),_vm._v(" Inhoud template bewerken ")])],1),_c('li',{staticStyle:{"width":"300px"}},[_c('a',{staticClass:"icon-button icon-button--text",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.copyTemplate(scope.row.id)}}},[_c('i',{staticClass:"fal fa-copy"}),_vm._v(" Template kopiëren ")])]),_c('li',{staticStyle:{"width":"300px"}},[_c('a',{staticClass:"icon-button icon-button--text",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteTemplate(scope.row.id)}}},[_c('i',{staticClass:"fal fa-trash-alt"}),_vm._v(" Template verwijderen ")])])])]}}])})],1),(_vm.user.isSuperAdmin)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();_vm.addDialogVisible = true}}},[_vm._v(" Voeg een template toe ")]):_vm._e(),(_vm.user.isSuperAdmin)?_c('el-dialog',{attrs:{"title":"Template toevoegen","visible":_vm.addDialogVisible},on:{"update:visible":function($event){_vm.addDialogVisible=$event}}},[_c('template-form',{attrs:{"saving":_vm.loading},on:{"submitted":_vm.addTemplate}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }