var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeManual)?_c('div',{staticClass:"manual-editor"},[_c('div',{staticClass:"manual-editor__heading"},[_c('h1',{staticClass:"manual-editor__title"},[_vm._v(" "+_vm._s(_vm.activeManual.title)),_c('br'),_c('small',{staticClass:"manual-editor__subtitle"},[_vm._v(_vm._s(_vm.activeManual.organisation.name))])]),(this.$route.params.organisationId && _vm.user.canEditManual)?_c('router-link',{staticClass:"manual-editor__link icon-button icon-button--text",attrs:{"target":"_blank","to":{
        name: 'ManualView',
        params: {
          manualId: _vm.activeManual.id,
          organisationId: this.$route.params.organisationId
        }
      }}},[_c('i',{staticClass:"fal fa-book-open"}),_vm._v(" "),_c('span',[_vm._v("Bekijk het handboek")])]):_vm._e()],1),_c('editor-chapter-add',{attrs:{"manualId":_vm.activeManual.id,"chapterIndexReservation":0}}),_c('draggable',{attrs:{"move":_vm.draggableMayMove,"ghost-class":"draggable--ghost","handle":".draggable__dragger"},on:{"start":function($event){_vm.dropped = false},"end":function($event){_vm.dropped = true}},model:{value:(_vm.draggableChapters),callback:function ($$v) {_vm.draggableChapters=$$v},expression:"draggableChapters"}},_vm._l((_vm.draggableChapters),function(chapter,index){return _c('div',{key:`chapter-${index}`},[_c('editor-chapter',{attrs:{"chapter":chapter,"dropped":_vm.dropped},on:{"editting":function($event){_vm.draggableMayNotMove = true},"edittingClosed":function($event){_vm.draggableMayNotMove = false}}}),_c('editor-chapter-add',{key:`chapter-add-${index}`,attrs:{"manualId":chapter.manual_id,"chapterIndexReservation":index + 1}})],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }