import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home'
import FourOFour from './components/404'
import Unavailable from './components/Unavailable'

import OrganisationOverview from './components/Organisation/Overview'
import OrganisationAdd from './components/Organisation/Add'
import OrganisationEdit from './components/Organisation/Edit'

import UserOverview from './components/User/Overview'
import UserUpdatePassword from './components/User/UpdatePassword'
import UserResetPassword from './components/User/ResetPassword'

import ManualEdit from './components/Manual/Edit'
import ManualView from './components/Manual/View'
import ManualEditor from './components/Manual/Editor/Editor'

import TemplateOverview from './components/Template/Overview'
import TemplateEdit from './components/Template/Edit'

import { siteTitle } from '@/config'

Vue.use(Router)

const routerInstance = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        permissions: [
          'superAdministrator',
          'administrator',
          'manualViewer',
          'manualViewerBasic',
          'manualManager',
          'auditor'
        ],
        title: siteTitle
      }
    },
    {
      path: '/organisations',
      name: 'OrganisationOverview',
      component: OrganisationOverview,
      meta: {
        permissions: ['superAdministrator', 'administrator', 'manualManager'],
        title: 'Alle organisaties'
      }
    },
    {
      path: '/organisations/new',
      name: 'OrganisationAdd',
      component: OrganisationAdd,
      meta: {
        permissions: ['superAdministrator'],
        title: 'Nieuwe organisatie'
      }
    },
    {
      path: '/organisations/:id/edit',
      name: 'OrganisationEdit',
      component: OrganisationEdit,
      meta: {
        permissions: ['superAdministrator', 'administrator', 'manualManager'],
        title: 'Nieuwe organisatie'
      }
    },
    {
      path: '/organisations/:id/users',
      name: 'UserOverview',
      component: UserOverview,
      meta: {
        permissions: ['superAdministrator', 'administrator', 'manualManager'],
        title: 'Gebruikersoverzicht'
      }
    },
    {
      path: '/organisations/:organisationId/manual/:manualId',
      name: 'ManualView',
      component: ManualView,
      meta: {
        permissions: [
          'superAdministrator',
          'administrator',
          'manualViewer',
          'manualViewerBasic',
          'manualManager',
          'auditor'
        ],
        title: 'Handboek bekijken'
      }
    },
    {
      path: '/organisations/:organisationId/manual/:manualId/edit',
      name: 'ManualEdit',
      component: ManualEdit,
      meta: {
        permissions: ['superAdministrator', 'manualManager'],
        title: 'Handboek wijzigen'
      }
    },
    {
      path: '/organisations/:organisationId/manual/:manualId/editor',
      name: 'ManualEditor',
      component: ManualEditor,
      meta: {
        permissions: ['superAdministrator', 'manualManager'],
        title: 'Handboek editor'
      }
    },
    {
      path: '/templates',
      name: 'TemplateOverview',
      component: TemplateOverview,
      meta: {
        permissions: ['superAdministrator'],
        title: 'Sjablonen overzicht'
      }
    },
    {
      path: '/templates/:manualId/edit',
      name: 'TemplateEdit',
      component: TemplateEdit,
      meta: {
        permissions: ['superAdministrator'],
        title: 'Sjabloon wijzigen'
      }
    },
    {
      path: '/templates/:manualId/editor',
      name: 'TemplateEditor',
      component: ManualEditor,
      meta: {
        permissions: ['superAdministrator', 'manualManager'],
        title: 'Handboek editor'
      }
    },
    {
      path: '/users/update-password',
      name: 'UserUpdatePassword',
      component: UserUpdatePassword,
      meta: {
        permissions: [
          'superAdministrator',
          'administrator',
          'manualViewer',
          'manualViewerBasic',
          'manualManager',
          'auditor'
        ],
        title: 'Wachtwoord updaten'
      }
    },
    {
      path: '/resetPassword',
      name: 'UserResetPassword',
      component: UserResetPassword,
      meta: {
        title: 'Wachtwoord resetten'
      }
    },
    {
      path: '/unavailable',
      name: 'unavailable',
      component: Unavailable,
      meta: {
        title: 'Geen toegang'
      }
    },
    {
      // will match everything
      path: '*',
      name: '404',
      component: FourOFour,
      meta: {
        title: 'Niet gevonden'
      }
    }
  ]
})
routerInstance.beforeEach((to, from, next) => {
  if (window.smartsupp !== undefined) {
    window.smartsupp('chat:hide')
  }
  if (from.name === 'ManualEditor') {
    const agree = window.confirm(
      'Je gaat de handboeken editor verlaten. Wil je dat?'
    )
    if (agree) {
      next()
    }
  } else {
    next()
  }
})
export default routerInstance
