var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Handboeken overzicht")]),_c('el-input',{staticClass:"search-box",attrs:{"type":"search","suffix-icon":"el-icon-search","placeholder":`Zoek op Titel${_vm.user.isSuperAdmin ? ' of organisatienaam' : ''}`},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),(_vm.user.isSuperAdmin)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();_vm.addDialogVisible = true}}},[_vm._v(" Voeg een handboek toe ")]):_vm._e(),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"has--clickable-row",attrs:{"stripe":"","data":_vm.manualListQueried,"default-sort":{ prop: 'version_date', order: 'descending' }},on:{"row-click":_vm.showManual}},[_c('el-table-column',{attrs:{"sortable":"","prop":"project_number","label":"Project","width":"100"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"title","label":"Titel","width":"300"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"organisation.name","label":"Organisatie"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"version","label":"Versie","width":"100","align":"center"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"version_date","label":"Laatst gewijzigd","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.versionDateFormatted)+" ")]}}])}),(_vm.user.canEditManual)?_c('el-table-column',{attrs:{"width":"75","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('context-menu',[_c('li',{staticStyle:{"width":"325px"}},[(_vm.user.isSuperAdmin)?_c('router-link',{staticClass:"icon-button icon-button--text",attrs:{"to":{
                name: 'ManualView',
                params: {
                  organisationId: scope.row.organisation.id,
                  manualId: scope.row.id
                }
              }}},[_c('i',{staticClass:"icon-button__icon fal fa-search"}),_vm._v(" Handboek bekijken ")]):_vm._e()],1),_c('li',{staticStyle:{"width":"325px"}},[(_vm.user.isSuperAdmin)?_c('router-link',{staticClass:"icon-button icon-button--text",attrs:{"to":{
                name: 'ManualEdit',
                params: {
                  organisationId: scope.row.organisation.id,
                  manualId: scope.row.id
                }
              }}},[_c('i',{staticClass:"icon-button__icon fal fa-pen"}),_vm._v(" Gegevens handboek wijzigen ")]):_vm._e()],1),_c('li',{staticStyle:{"width":"325px"}},[_c('router-link',{staticClass:"icon-button icon-button--text",attrs:{"to":{
                name: 'ManualEditor',
                params: {
                  manualId: scope.row.id,
                  organisationId:
                    scope.row.organisation.id || _vm.user.organisation_id
                }
              }}},[_c('i',{staticClass:"icon-button__icon fal fa-book"}),_vm._v(" Inhoud handboek wijzigen ")])],1),_c('li',{staticStyle:{"width":"325px"}},[(_vm.user.isSuperAdmin && scope.row.id !== 1)?_c('a',{staticClass:"icon-button icon-button--text",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteManual(scope.row.id)}}},[_c('i',{staticClass:"icon-button__icon fal fa-trash-alt"}),_vm._v(" Handboek verwijderen ")]):_vm._e()])])]}}],null,false,1776604191)}):_vm._e()],1),(_vm.user.isSuperAdmin)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();_vm.addDialogVisible = true}}},[_vm._v(" Voeg een handboek toe ")]):_vm._e(),(_vm.user.isSuperAdmin)?_c('el-dialog',{attrs:{"title":"Handboek toevoegen","visible":_vm.addDialogVisible},on:{"update:visible":function($event){_vm.addDialogVisible=$event}}},[_c('manual-form',{ref:"manualForm",attrs:{"saving":_vm.loading},on:{"submitted":_vm.addManual,"cancel":_vm.closeForm}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }