<template>
  <div class="login-container" v-if="!user">
    <img :src="configLogoUrl" alt="Logo" style="margin: 22px 0;" />
    <el-card class="login-container__card" v-loading="loading">
      <el-form class="login-form">
        <el-form-item label="E-mailadres">
          <el-input
            type="text"
            name="username"
            v-model="username"
            autofocus
          ></el-input>
        </el-form-item>
        <el-form-item label="Wachtwoord">
          <el-input
            type="password"
            name="password"
            v-model="password"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>

        <div class="login-form__footer">
          <el-button type="primary" @click.prevent="login">Login</el-button>
          <el-button
            class="login-form__link"
            type="text"
            @click="resetDialogVisible = true"
          >
            <small>Wachtwoord vergeten</small>
          </el-button>
        </div>
      </el-form>
    </el-card>
    <el-dialog
      append-to-body
      title="Wachtwoord reset aanvragen"
      class="reset-password-dialog el-dialog--small"
      :visible.sync="resetDialogVisible"
    >
      <user-reset-password-request />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UserResetPasswordRequest from '@/components/User/ResetPasswordRequest'
import { bearerTokenName, setBearerToken, logoUrl } from '@/config'
import { bus } from '@/main'
export default {
  name: 'UserLogin',
  components: {
    UserResetPasswordRequest
  },
  computed: {
    ...mapState('user', ['user'])
  },
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      resetDialogVisible: false,
      configLogoUrl: ''
    }
  },
  mounted() {
    this.configLogoUrl = logoUrl

    this.checkUserSignin()

    bus.$on('userSessionIsExpired', () => {
      this.checkUserSignin()
    })

    bus.$on('userSessionIsExpiring', (val) => {
      this.$notify({
        title: 'De sessie gaat verlopen',
        message: 'Let op, over ' + val + ' minuten verloopt de sessie. Sla de gegevens nu op.',
        type: 'warning'
      })
    })
  },
  methods: {
    checkUserSignin() {
      // if there's a bearerToken; try to login the user.
      // if it fails, show the loginscreen. Otherwise the user will be redirected automatically by app.vue
      const bearerToken = localStorage.getItem(bearerTokenName)
      const expirationTimestamp = parseInt(
        localStorage.getItem('kysExpirationTimestamp')
      )

      if (bearerToken && expirationTimestamp) {
        this.loading = true
        const now = new Date().getTime()
        // check if the expirationDate is exceeded and we should request a refresh token
        if (now >= expirationTimestamp) {
          // for now, just logout the user after expiration
          this.$store.commit('user/logOut')
          this.loading = false

          this.$notify({
            title: 'De sessie is verlopen',
            message: 'Log opnieuw in in het systeem',
            type: 'error'
          })
          /*
          // this.$store.commit('user/logOut')
          this.$store.dispatch('user/refreshAuth', bearerToken).then(
            () => {
              // do nothing?
              this.loading = false
            },
            error => {
              this.$notify({
                title: 'Er is iets misgegaan. De melding is:',
                message: error,
                type: 'error'
              })
              this.$store.commit('user/logOut')
              this.loading = false
            }
          )
          */
        } else {
          // the expirationTimestamp wasnt exceeded, get the user
          this.getUser()
        }
      }
    },
    getUser() {
      const bearerToken = localStorage.getItem(bearerTokenName)
      // If everything goes according to plan there should be a (new) bearer token present to be able to fetch the user now.
      this.$store.dispatch('user/getUser', bearerToken).then(
        () => {
          this.loading = false

          // the bearer token is still valid, so set it for the axios interceptor
          setBearerToken(bearerToken)
          if (this.$router.name === 'login') {
            this.$router.push({ name: 'home' })
          }
        },
        error => {
          this.$notify({
            title: 'Er is iets misgegaan. De melding is:',
            message: error,
            type: 'error'
          }) // error has to be handled so we can console log it for debug reasons
          // The user cant be logged in. "Log out" the user to destroy its cookie
          this.$store.commit('user/logOut')
        }
      )
    },
    login() {
      this.loading = true
      this.$store
        .dispatch('user/login', {
          payload: {
            email: this.username,
            password: this.password
          }
        })
        .then(
          response => {
            // login success
            this.loading = false
            this.username = ''
            this.password = ''
          },
          error => {
            this.loading = false

            this.$notify({
              title: 'Oeps! Het volgende ging mis:',
              message: error,
              type: 'error'
            })
          }
        )
    }
  }
}
</script>
<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
.login-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: url('/background.svg') no-repeat;
  background-size: cover;

  &__logo {
    width: 240px;
    margin: 1rem auto 2rem;
    display: block;
  }

  &__card {
    max-width: 500px;
    width: 100%;
  }
}

.login-form {
  &__footer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  &__loader {
    width: 60px;
    height: 60px;
    display: none;
  }
}
</style>
