import axios from 'axios'
export const baseURL =
  // 'http://127.0.0.1:8000/api/'
  process.env.NODE_ENV === 'development'
    ? 'https://nieuw-api.mijnhandboeken.nl/api/'
    : process.env.VUE_APP_API_URL

export const bearerTokenName = 'kysBearerToken' // used as localStorage variable with the current bearerToken
const Api = axios.create({
  baseURL
})

let bearerInterceptor = null
let bearerTokenGlobal = null
export function setBearerToken(bearerToken) {
  if (bearerInterceptor !== null) {
    // reset the interceptor if one is present to prevent new calls made to the old bearer(After one logs out and in with another account for example)
    Api.interceptors.request.eject(bearerInterceptor)
    bearerInterceptor = null
    bearerTokenGlobal = null
  }
  bearerInterceptor = Api.interceptors.request.use(function(config) {
    if (bearerToken && config?.headers) {
      config.headers.Authorization = `Bearer ${bearerToken}`
      bearerTokenGlobal = bearerToken
    }
    return config
  })
}

export { Api, bearerTokenGlobal }

/** ******** PASSWORD REQUIREMENT CONSTANT ***********/

export const passwordRequirementCheck = (rule, value, callback) => {
  if (value) {
    const requirementRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\da-zA-Z]).{8,}$'
    )
    if (!requirementRegex.test(value)) {
      callback(
        new Error(
          'Het wachtwoord moet minstens bestaan uit 1 nummer, hoofdletter, getal en speciaal teken'
        )
      )
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export const passwordStrings = {
  empty: 'Wachtwoord mag niet leeg zijn',
  emptyConfirm: 'Het wachtwoord vergeten veld mag niet leeg zijn',
  passwordsDontMatch: 'De wachtwoorden komen niet overeen'
}

export const privacyStatementUrl = process.env.VUE_APP_PRIVACY_URL
export const logoUrl = process.env.VUE_APP_LOGO
export const smartsuppKey = process.env.VUE_APP_SMARTSUPP_KEY
export const privacyTitle = process.env.VUE_APP_PRIVACY_TITLE
export const manualTitle = process.env.VUE_APP_MANUAL_TITLE
export const siteTitle = process.env.VUE_APP_SITE_TITLE
